<template>
  <div class="selectForm">
    <div v-for="(item, index) in selectData" :key="index" class="select_list">
      <!-- label -->
      <div class="select_title">{{ item.label }}</div>
      <!-- 输入框 -->
      <a-input
        v-model="listQuery[item.prop]"
        v-if="item.type === 'input'"
        placeholder="请输入"
        :style="{ width: item.width ? item.width : '104px' }"
      />
      <!-- 筛选种类 -->
      <a-select
        v-model="listQuery[item.prop]"
        v-if="item.type === 'select'"
        :style="{ width: item.width ? item.width : '104px' }"
        :mode="item.mode ? item.mode : 'default'"
        :placeholder="item.placeholder ? item.placeholder : '请选择'"
        :default-value="[]"
        @change="handleChange(item, $event)"
      >
        <a-select-option
          v-for="(i, index) in item.option"
          :key="index"
          :value="i.code"
        >
          {{ i.name }}
        </a-select-option>
      </a-select>

      <!-- 时间控件 -->
      <a-range-picker
      v-if="item.type ==='datePicker'"
        v-model="listQuery[item.options.prop]"
        @change="onChange"
        :style="{
          width: item.options.width ? item.options.width : '240px',
        }"
      />

      <!-- 组合式 左:select 右:select-->
      <a-input-group compact v-if="item.type === 'group-select-select'">
        <a-select
          v-model="listQuery[item.leftOptions.prop]"
          :style="{
            width: item.leftOptions.width ? item.leftOptions.width : '104px',
          }"
          :mode="item.leftOptions.mode ? item.leftOptions.mode : 'default'"
          :placeholder="
            item.leftOptions.placeholder
              ? item.leftOptions.placeholder
              : '请选择'
          "
          :default-value="[]"
          :maxTagCount="
            item.leftOptions.maxTagCount ? item.leftOptions.maxTagCount : 10
          "
        >
          <a-select-option
            v-for="(i, index) in item.leftOptions.option"
            :key="index"
          >
            {{ i.name }}
          </a-select-option>
        </a-select>
        <a-select
          v-model="listQuery[item.rightOptions.prop]"
          :style="{
            width: item.rightOptions.width ? item.rightOptions.width : '104px',
          }"
          :mode="item.rightOptions.mode ? item.rightOptions.mode : 'default'"
          :placeholder="
            item.rightOptions.placeholder
              ? item.rightOptions.placeholder
              : '请选择'
          "
          :default-value="[]"
        >
          <a-select-option
            v-for="(i, index) in item.rightOptions.option"
            :key="index"
          >
            {{ i.name }}
          </a-select-option>
        </a-select>
      </a-input-group>

      <!-- 组合式 左:select 右:datePicker-->
      <a-input-group compact v-if="item.type === 'group-select-datePicker'">
        <a-select
          v-model="listQuery[item.leftOptions.prop]"
          :style="{
            width: item.leftOptions.width ? item.leftOptions.width : '104px',
          }"
          :mode="item.leftOptions.mode ? item.leftOptions.mode : 'default'"
          :placeholder="
            item.leftOptions.placeholder
              ? item.leftOptions.placeholder
              : '请选择'
          "
          :default-value="[]"
          :maxTagCount="
            item.leftOptions.maxTagCount ? item.leftOptions.maxTagCount : 10
          "
        >
          <a-select-option
            v-for="(i, index) in item.leftOptions.option"
            :key="index"
          >
            {{ i.name }}
          </a-select-option>
        </a-select>
        <a-range-picker
          v-model="listQuery[item.rightOptions.prop]"
          @change="onChange"
          :style="{
            width: item.rightOptions.width ? item.rightOptions.width : '240px',
          }"
        />
      </a-input-group>
    </div>

    <div class="button">
      <a-button class="resetting" @click="reset">重置</a-button>
      <a-button type="primary" @click="submit">
        查询
      </a-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "SelectForm",
  props: {
    selectData: {
      type: Array,
    },
  },
  data() {
    return {
      listQuery: {},
    };
  },
  methods: {
    handleChange(e, event) {
      console.log("handleChange=====", e, event);
      // this.listQuery[e.prop] = event;
    },
    onChange(e) {
      console.log("onChange=====", e);
    },
    reset() {
      this.listQuery = {};
    },
    submit() {
      this.$emit("search", this.listQuery);
    },
  },
};
</script>

<style scoped lang="less">
.selectForm {
  // border: 1px solid red;
  background: #ffffff;
  border-radius: 6px;
  display: flex;
  flex-wrap: wrap;
  // justify-content: center;
  .select_list {
    margin: 10px 15px;
  }
  .select_title {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #262626;
    margin-bottom: 10px;
  }
  .button {
    height: 100%;
    position: relative;
    bottom: -40px;
    .resetting {
      margin: 0 10px;
    }
  }
}

::v-deep .ant-select-selection__choice {
  background: rgba(22, 122, 255, 0.09);
  border: 1px solid #cbe1ff;
  color: #1d6aff;
  i {
    color: #1d6aff !important;
  }
}
</style>

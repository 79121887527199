<template>
  <a-config-provider :locale="zhCN">
    <div class="selectForm">
      <a-table
        :loading="loading"
        class="table"
        :columns="columns"
        :data-source="tableData"
        @change="onChange"
        :pagination="false"
        bordered
      >
        <!-- 渲染插槽 -->
        <div
          v-for="(item, index) in columns.filter((i) => i.scopedSlots)"
          :slot="item.scopedSlots.customRender"
          slot-scope="scope"
          :key="index"
        >
          <slot :name="item.scopedSlots.customRender" :row="scope"> </slot>
        </div>
      </a-table>
      <div class="pagination">
        <a-pagination
          show-size-changer
          :default-current="3"
          :total="300"
          @showSizeChange="onShowSizeChange"
          :show-total="(total) => `总数 ${total} 条`"
        />
      </div>
    </div>
  </a-config-provider>
</template>

<script>
import zhCN from "ant-design-vue/es/locale-provider/zh_CN"; //中文包
export default {
  name: "SelectForm",
  props: {
    columns: {
      type: Array,
    },
    tableData: {
      type: Array,
    },
    loading: {
      type: Boolean,
    },
  },
  data() {
    return {
      pageSize: 20,
      current: 4,
      zhCN,
    };
  },
  watch: {
    pageSize(val) {
      console.log("pageSize", val);
    },
    current(val) {
      console.log("current", val);
    },
  },
  methods: {
    onShowSizeChange(current, pageSize) {
      console.log(current, pageSize);
    },
    onChange(e) {
      console.log("onChange", e);
    },
  },
};
</script>

<style scoped lang="less">
.selectForm {
  // border: 1px solid red;
  background: #ffffff;
  border-radius: 6px;
  display: flex;
  flex-wrap: wrap;
  // justify-content: center;
  .table {
    width: 100%;
  }
  .pagination {
    padding: 20px;
    width: 100%;
    display: flex;
    justify-content: end;
  }
}
</style>

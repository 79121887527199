import axios from "axios";
import { request } from "@/utils/request";

/**
 * 获取病人和门店列表信息
 * @param {*} data
 * @returns
 */
export function getMedicineProjectTab(data = {}) {
  return request({
    url: `/med/api/MedicinePlatform/get-medicine-project-tab`,
    method: "POST",
    data,
    // headers: {
    //   "Content-Type": "application/x-www-form-urlencoded",
    // },
  });
}

/**
 * 获取门店表头组
 * @param {*} data
 * "projectCode": "001"
 * @returns
 */
export function getMedicineProjectGroupQuery(data = {}) {
  return request({
    url: `/med/api/MedicinePlatform/get-medicine-project-group-query`,
    method: "POST",
    data,
    // headers: {
    //   "Content-Type": "application/x-www-form-urlencoded",
    // },
  });
}

/**
 * 获取填充逻辑表项目
 * @param {*} data
 *  "projectCode": "001",
 *  "projectTabCode": "001001",
 * @returns
 */
export function getMedicineProjectFillruleList(data = {}) {
  return request({
    url: `/med/api/MedicinePlatform/get-medicine-project-fillrule-list`,
    method: "POST",
    data,
    // headers: {
    //   "Content-Type": "application/x-www-form-urlencoded",
    // },
  });
}


/**
 * 保存填充逻辑表项目
 * @param {*} data
 *  "projectCode": "001",
 *  "projectTabCode": "001001",
 * @returns
 */
export function saveMedicineProjectFillrule(data) {
  return request({
    url: `/med/api/MedicinePlatform/batch-save-medicine-project-fillrule`,
    method: "POST",
    data,
    // headers: {
    //   "Content-Type": "application/x-www-form-urlencoded",
    // },
  });
}

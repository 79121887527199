import axios from "axios";
import { request } from "@/utils/request";

/**
 * 获取医学项目列表
 * @param {*} data
 * @returns
 */
export function getMedicineProjectList(data = {}) {
  return request({
    url: `/med/api/MedicinePlatform/get-medicine-project-list`,
    method: "POST",
    data,
    // headers: {
    //   "Content-Type": "application/x-www-form-urlencoded",
    // },
  });
}
